#hidden-video{
    height: 0;
}
.stream-component{
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;

    &__video {
        width: 100%;
        height: 100%;
        float: left;
        cursor: pointer;
        object-fit: contain;
    }

    &__placeholder {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1001;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__user {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h4 {
            margin-top: 10px;
            margin-bottom: 0;
        }

        .thumbnail {
            width: 80px;
            height: 80px;
        }
    }
}



