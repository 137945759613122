.chatbox-header {
    border-bottom: 2px solid #F6F6F6;

    &-menu {
        display: flex;
        flex-wrap: nowrap;
        flex: 1 1 auto;
        margin-left: -15px;
        margin-right: -15px;
        
        &__info {
            display: flex;
            flex: 1 1 auto;
            padding: 15px 10px;
            min-width: 0;
    
            &-text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 1;
                min-width: 0;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        
        &__button {
            padding: 15px 15px;
            flex-shrink: 0;
            min-width: 40px;
            justify-content: center;
    
            &-group {
                display: flex;
                flex: 0 auto;
                padding: 10px;

                > :not(:last-child) {
                    margin-right: 10px;
                }
    
                .button {
                    padding: 10px 15px;
                    align-items: center;
                    flex-grow: 1;

                    @media (max-width: 767px) {
                        background: transparent !important;

                        &:hover {
                            background: #F7F7F7 !important;
                        }

                        img {
                            filter: none;
                        }

                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }


}

.video-call {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: column;
    flex: 1 1 auto;
    background: #000000;
    z-index: 1001;
    min-height: 0;
    transition: .3s all ease;
    display: none;

    &__user {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        z-index: 1000;
        min-height: 0;

        &--minimize {
            position: absolute;
            top: calc( 55px + 15px);
            left: 15px;
            height: 140px;
            width: 110px;
            z-index: 1001;

            @media (min-width: 768px) {
                right: 30px;
                left: auto;
            }
        }
    }

    &__navbar {
        display: flex;
        flex: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1001;
        background: rgba(64, 72, 82, 0.6);
        height: 55px;

        &-button {
            padding: 15px;
            flex-shrink: 0;
            display: flex;

        }

        &-info {
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
            color: #FFFFFF;
            padding: 10px;
            min-width: 0;

            @media (min-width: 768px) {
                justify-content: center;
            }
        }

        .minimize-desktop {
            display: none;
            margin: 10px;
        }

        @media (min-width: 768px) {
            height: 75px;

            .minimize-mobile,
            .switch-camera {
                display: none;
            }

            .minimize-desktop {
                display: flex;
            }
        }
    }

    &__action {
        display: flex;
        justify-content: center;
        flex: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1001;
        padding: 30px;
        transition: .3s all ease;

        button {
            padding: 0;

            &:hover {
                opacity: 0.8;
            }

            &:not(:last-child) {
                margin-right: 30px;
            }
        }
    }

    &.active {
        display: flex;
    }

    &.split {
        flex: 0 1 50%;
        position: relative;

        &~ .chat {
            flex: 0 1 50%;
        }
    }

    @media (min-width: 768px) {
        &:not(.active) {
            top: 100px;
            right: 30px;
            left: auto;
            display: flex;
            width: 400px;
            height: 250px;
        }

        &:hover {
            .video-call__action {
                opacity: 1;
            }
        }

        .video-call__action {
            opacity: 0;
        }
    }
}

.video-call-alert {
    background: #4CDA63;
    cursor: pointer;
    &__info {
        display: flex;
        flex: 1;
        padding-top: 10px;
        padding-bottom: 10px;

        &-text {
            flex-grow: 1;
        }
    }

    @media (min-width: 768px) {
        display: none;
    }
}

.browser-notice {
    &__header {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    &__message {
        margin-top: 15px;
        margin-bottom: 15px;

        p {
            line-height: 20px;
            margin-bottom: 10px;
        }

        &--windows {
            &.card {
                display: flex;
                flex: 1 1 auto;
                flex-direction: column;
                align-items: center;

                .card-body {
                    width: 700px;
                    max-width: 100%;
                }
            }
        }
    }
    &__action {
        margin-top: 15px;
    }

    &__row {
        display: flex;
        flex: 1 1 auto;
        margin-right: -15px;
        margin-left: -15px;
    }

    &__col {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (max-width: 767px) {
        .card {
            border-radius: 0px;
            margin-right: -15px;
            margin-left: -15px;
        }
    }
}