.chat {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 0;

    &__log {
        display: flex;
        flex-grow: 1;
        overflow: auto;
        min-height: 0;

        .container {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            min-width: 0;
        }

        &--not-started {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
        }

        &-notice {
            text-align: center;

            *:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    &__input {
        border-top: 2px solid #F6F6F6;
        flex-grow: 0;

        &-rate {
            padding: 10px;
            display: flex;
            flex: 1;
            flex-direction: column;

            .button {
                justify-content: center;
            }
        }

        &-group {
            display: flex;
            align-items: flex-end;
        }

        &-box {
            background: #F5F5F5;
            border: none;
            padding: 7px 15px;
            color: #404852;
            outline: none;
            border-radius: 20px;
            flex-grow: 1;
            margin: 10px;
            line-height: 21px;
            resize: none;

            &-wrapper {
                display: flex;
                flex-grow: 1;
                flex-direction: column;
                min-width: 0;
            }
        }

        &-send {
            display: flex;
            flex-shrink: 0;
            padding: 0;
            filter: grayscale(1) brightness(155%);
            margin: 10px 0px;

            &--active {
                filter: none;

                &:focus,
                &:hover {
                    opacity: .6;
                }
            }
        }

        &-attachment {
            display: flex;
            align-items: center;
            padding: 0;
            transition: .3s all ease;
            margin: 10px 0px;

            &-button {
                display: flex;
                flex-shrink: 0;

                .row-filename,
                .row-filename-empty {
                    display: none;
                }
            }

            &:hover,
            &:focus {
                opacity: .6;
            }
        }
    }
}

.chat-attachment {
    display: flex;
    flex: 1 1 auto;
    background: #F5F5F5;
    margin: 10px;
    padding: 10px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;

    &:not(:last-child) {
        margin: 10px 10px 0px 10px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    &__preview {
        background: #FFFFFF;
        border-radius: 10px;
        flex: 1;
        position: relative;
        min-width: 0;

        &-info {
            display: flex;
            flex: 1;
            padding: 10px;
            align-items: center;
            min-width: 0;
        }

        &-clear {
            position: absolute;
            top: 0;
            right: 0;
        }

        .thumbnail {
            width: 50px;
            height: 50px;
            margin-right: 10px;

            @media (min-width: 768px) {
                width: 80px;
                height: 80px;
            }
        }
    }

    &~ .chat__input-box {
        margin-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

.chat-message {
    display: flex;
    margin-bottom: 5px;
    margin-top: 5px;
    justify-content: flex-start;

    &__image {
        margin-bottom: 10px;
        cursor: pointer;
        position: relative;
        .thumbnail {
            flex: 1;
            width: auto;
            height: 150px;
            &--square{
                width: 150px;
            }
        }

        .blur {
            img {
                filter: blur(20px);
                -webkit-filter: blur(20px);
            }
        }

        .retry-button{
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -30px;
            margin-top: -10px;
            width: 60px;
            justify-content: center;
            font-weight: bold;
        }
    }

    &__attachment {
        margin-bottom: 10px;
        display: flex;
        background: #FFFFFF;
        padding: 10px;
        border-radius: 5px;
        align-items: center;
        min-width: 0;

        .thumbnail {
            margin-right: 10px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 10px;
        background-color: var(--color-lightGrey);
        border-radius: 10px 10px 10px 0px;
        max-width: 90%;
    }

    &__content-time {
        margin-top: 5px;
        text-align: right;
        color: #C5C8CB;
    }

    &__content-call {
        display: flex;
        flex-wrap: nowrap;

        &--icon {
            padding-right: 10px;
        }
    }

    &.current-user {
        justify-content: flex-end;

        .chat-message__content {
            border-radius: 10px 10px 0px 10px;
            color: #FFFFFF;
            background-color: var(--color-primary);
            }

        .chat-message__content-time {
            color: #FFFFFF;
        }
    }
}

.chat-py-8 {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
}

.chat-nav {
    display: flex;
    flex: 0 1 auto;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #EAEAEA;

    &__row {
        display: flex;
        flex: 1 1 auto;
        margin-left: -15px;
        margin-right: -15px;
    }

    &__col {
        padding-left: 15px;
        padding-right: 15px;
        flex-grow: 1;
        display: flex;
    }
}

.alert {
    background: var(--color-lightGreen);
    color: var(--color-success);
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    display: inline-block;
    margin: 10px auto;

    &--full {
        display: inline-flex;
        margin: 0;
        justify-content: center;
    }
}