.button {
    padding: 10px;
    transition: .3s all ease;
    display: flex;
    flex-shrink: 0;
    border-radius: 5px;
    &:disabled {
        filter: grayscale(1) brightness(100%);
        pointer-events: none;
    }
    &.clear:hover {
        background: #F5F5F5;
    }

    &.green {
        background: #41D6C3;
        color: #FFFFFF;
        
        &:hover {
            background: darken(#41D6C3, 10%);
        }
    }

    &.red {
        background: #EE5454;
        color: #FFFFFF;

        &:hover {
            background: darken(#EE5454, 10%);
        }
    }
}


