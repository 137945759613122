// layout ( position )
%base {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#root {
    @extend %base;
}

.container {
    width: 100%;
}

.row-vertical {
    margin-top: -15px;
    margin-bottom: -15px;
    display: flex;
    flex-direction: column;

    &__item {
        padding-top: 15px;
        padding-bottom: 15px
    }

    &--sm {
        margin-top: -7.5px;
        margin-bottom: -7.5px;

        .row-vertical__item {
            padding-top: 7.5px;
            padding-bottom: 7.5px
        }
    }
}

.container {
  max-width: var(--grid-maxWidth);
  margin: 0 auto;
  padding: 0 calc(var(--grid-gutter)/2);
  width: auto;
  flex: 1;
}

.debug{
    display: block;
    background-color: #f5f5f5;
    color: black;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    border: 1px solid #eee;
    box-shadow: 0px 0px 8px 0px #cecece;
    ul {
        height: 500px;
        overflow-y: scroll;
        list-style: none;
        margin: 0;
        padding: 1em;
    }
    li {
        padding: 1em;
        font-family: monospace;
        font-size: 12px;
        font-style: italic;
    }
    &.hide {
        display: none;
    }
}