.rounded-0 {
    border-radius: 0;
}

.mt-auto {
    margin-top: auto;
}

.w-100 {
    width: 100%;
}

.bg-gray {
  background: #f7f7f7;
}

.text-semi-bold {
    font-weight: 500;
}

.text-center {
    text-align: center;
}

.text-bold {
    font-weight: 600;
}

.text-white {
    color: #FFFFFF;
}

.text-gray {
    color: #9FA3A8;
}

.text-green {
    color: #41D6C3;
}

.text-red {
    color: #C94656;
}

.text-blue {
    color: #42A1EC;
}

.text-black {
    color: #404852;
}

.text-dark-blue {
    color: #195178;
}

.text-yellow {
    color: #ffcd00;
}

.text-red {
    color: #C94656;
}

.list-clean {
    list-style: none;
    padding: 0;
    margin: 0;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.img-white {
    filter: brightness(0) invert(1);;
}

.d-none {
  display: none;
}

.d-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.img-responsive {
  max-width: 100%;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.thumbnail {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
    flex-shrink: 0;

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &--square {
        border-radius: 4px;
    }
}

.align-items-center {
    align-items: center;
}

.link-blue {
    cursor: pointer;
    color: #42A1EC;
    
    &:active, 
    &:focus, 
    &:hover {
        color: #42A1EC;
        text-decoration: underline;
    }
}

.card {
    background: #FFFFFF;
    border-radius: 4px;

    &-body {
        padding: 15px;
    }
}

.row {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;

    [class^='col'] {
        padding-left: 15px;
        padding-right: 15px;
    }

    &--around {
        &-xs {
            margin: -5px;

            [class^='col'] {
                padding: 5px;
            }
        }
    }

    .col-12 {
        -ms-flex: 0 1 100%;
        flex: 0 1 100%;
        max-width: 100%;
    }

    @media (min-width: 768px) {
        .col-md-6 {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}

.m {
    &l {
        &-1 {
            margin-left: 5px;
        }
    }
}