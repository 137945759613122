// for standard html style

%base {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    --color-primary: #42A1EC;
    --color-secondary: #ebf5fd;
    --color-lightGrey: #F5F5F5;
    --color-grey: #747681;
    --color-darkGrey: #3f4144;
    --color-error: #d43939;
    --color-success: #41D6C3;
    --color-lightGreen: #E4F8F6;
    --grid-maxWidth: 1140px;
    --grid-gutter: 30px;
    --font-size: 14px;
    --font-family: "Helvetica Neue", sans-serif;
}

* {
    font-family: 'Raleway', sans-serif;
}

html, body, #root {
    @extend %base;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 100%;
    height: 100%;
    font-size: 14px;
}

body {
    background: #FFFFFF;
}

a {
    text-decoration: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul {
    li {

    }
}

input{
    font-size: 14px;
}
    
textarea {
    font-size: 14px;
}

ol {
    li {

    }
}

button {
    background-color: transparent;
    border: 0px;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    transition: .3s all ease;

}

input, textarea {
    font-size: 14px;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0
}

h6 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
}

p {
    line-height: 1.2;
    margin: 0;
}

small {
    font-size: 11px;
}